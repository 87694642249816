/**
 * @flow
 * @relayHash 92a64f82738251dcfb5aeb4f01661b5d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTagInput = {
  fragment?: ?string,
  annotated_id?: ?string,
  annotated_type?: ?string,
  tag: string,
  clientMutationId?: ?string,
};
export type MediaTagsCreateTagMutationVariables = {|
  input: CreateTagInput
|};
export type MediaTagsCreateTagMutationResponse = {|
  +createTag: ?{|
    +project_media: ?{|
      +tags: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +tag_text: ?string
          |}
        |}>
      |}
    |}
  |}
|};
export type MediaTagsCreateTagMutation = {|
  variables: MediaTagsCreateTagMutationVariables,
  response: MediaTagsCreateTagMutationResponse,
|};
*/


/*
mutation MediaTagsCreateTagMutation(
  $input: CreateTagInput!
) {
  createTag(input: $input) {
    project_media {
      tags(first: 100) {
        edges {
          node {
            tag_text
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTagInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateTagInput!"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tag_text",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaTagsCreateTagMutation",
  "id": null,
  "text": "mutation MediaTagsCreateTagMutation(\n  $input: CreateTagInput!\n) {\n  createTag(input: $input) {\n    project_media {\n      tags(first: 100) {\n        edges {\n          node {\n            tag_text\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaTagsCreateTagMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createTag",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateTagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": "tags(first:100)",
                "args": v2,
                "concreteType": "TagConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Tag",
                        "plural": false,
                        "selections": [
                          v3
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaTagsCreateTagMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createTag",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateTagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": "tags(first:100)",
                "args": v2,
                "concreteType": "TagConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Tag",
                        "plural": false,
                        "selections": [
                          v3,
                          v4
                        ]
                      }
                    ]
                  }
                ]
              },
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '29c0e305c0690d2d7acee6d266bd60db';
module.exports = node;
