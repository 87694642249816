/**
 * @flow
 * @relayHash 2a3626ea26c61c1408d5e39fc3d9720d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DestroyTagInput = {
  id?: ?string,
  clientMutationId?: ?string,
};
export type MediaTagsDeleteTagMutationVariables = {|
  input: DestroyTagInput
|};
export type MediaTagsDeleteTagMutationResponse = {|
  +destroyTag: ?{|
    +project_media: ?{|
      +tags: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +tag_text: ?string
          |}
        |}>
      |}
    |}
  |}
|};
export type MediaTagsDeleteTagMutation = {|
  variables: MediaTagsDeleteTagMutationVariables,
  response: MediaTagsDeleteTagMutationResponse,
|};
*/


/*
mutation MediaTagsDeleteTagMutation(
  $input: DestroyTagInput!
) {
  destroyTag(input: $input) {
    project_media {
      tags(first: 100) {
        edges {
          node {
            tag_text
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DestroyTagInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DestroyTagInput!"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tag_text",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MediaTagsDeleteTagMutation",
  "id": null,
  "text": "mutation MediaTagsDeleteTagMutation(\n  $input: DestroyTagInput!\n) {\n  destroyTag(input: $input) {\n    project_media {\n      tags(first: 100) {\n        edges {\n          node {\n            tag_text\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaTagsDeleteTagMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyTag",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyTagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": "tags(first:100)",
                "args": v2,
                "concreteType": "TagConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Tag",
                        "plural": false,
                        "selections": [
                          v3
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaTagsDeleteTagMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "destroyTag",
        "storageKey": null,
        "args": v1,
        "concreteType": "DestroyTagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": "tags(first:100)",
                "args": v2,
                "concreteType": "TagConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Tag",
                        "plural": false,
                        "selections": [
                          v3,
                          v4
                        ]
                      }
                    ]
                  }
                ]
              },
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3de50bda9668f11c804f4bc8392d530';
module.exports = node;
