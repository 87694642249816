/**
 * @flow
 * @relayHash 813beb6594fc6fbc2cc0b0b4f70f2e4e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MediaTagsQueryVariables = {|
  ids: string
|};
export type MediaTagsQueryResponse = {|
  +project_media: ?{|
    +dbid: ?number,
    +permissions: ?string,
    +team: ?{|
      +slug: string,
      +tag_texts: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +text: ?string
          |}
        |}>
      |},
    |},
    +tags: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +tag_text: ?string,
        |}
      |}>
    |},
  |}
|};
export type MediaTagsQuery = {|
  variables: MediaTagsQueryVariables,
  response: MediaTagsQueryResponse,
|};
*/


/*
query MediaTagsQuery(
  $ids: String!
) {
  project_media(ids: $ids) {
    dbid
    permissions
    team {
      slug
      tag_texts(last: 100) {
        edges {
          node {
            text
            id
          }
        }
      }
      id
    }
    tags(last: 100) {
      edges {
        node {
          id
          tag_text
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ids",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "last",
    "value": 100,
    "type": "Int"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "text",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": "tags(last:100)",
  "args": v5,
  "concreteType": "TagConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TagEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Tag",
          "plural": false,
          "selections": [
            v7,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "tag_text",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MediaTagsQuery",
  "id": null,
  "text": "query MediaTagsQuery(\n  $ids: String!\n) {\n  project_media(ids: $ids) {\n    dbid\n    permissions\n    team {\n      slug\n      tag_texts(last: 100) {\n        edges {\n          node {\n            text\n            id\n          }\n        }\n      }\n      id\n    }\n    tags(last: 100) {\n      edges {\n        node {\n          id\n          tag_text\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaTagsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tag_texts",
                "storageKey": "tag_texts(last:100)",
                "args": v5,
                "concreteType": "TagTextConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagTextEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TagText",
                        "plural": false,
                        "selections": [
                          v6
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v8
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaTagsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tag_texts",
                "storageKey": "tag_texts(last:100)",
                "args": v5,
                "concreteType": "TagTextConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagTextEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TagText",
                        "plural": false,
                        "selections": [
                          v6,
                          v7
                        ]
                      }
                    ]
                  }
                ]
              },
              v7
            ]
          },
          v8,
          v7
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '11d592387d18a0f8d370da28dde85ad5';
module.exports = node;
