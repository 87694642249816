/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MediaMainItemPreview_projectMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaSuggestionBanner_projectMedia$ref: FragmentReference;
export type MediaSuggestionBanner_projectMedia = {|
  +is_suggested: ?boolean,
  +suggested_main_relationship: ?{|
    +id: string
  |},
  +suggested_main_item: ?{|
    +$fragmentRefs: MediaMainItemPreview_projectMedia$ref
  |},
  +$refType: MediaSuggestionBanner_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MediaSuggestionBanner_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "is_suggested",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "suggested_main_relationship",
      "storageKey": null,
      "args": null,
      "concreteType": "Relationship",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "suggested_main_item",
      "storageKey": null,
      "args": null,
      "concreteType": "ProjectMedia",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "MediaMainItemPreview_projectMedia",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f529e272e89345b4d60d629fffe36cc2';
module.exports = node;
