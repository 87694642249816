/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaArticlesCard_article$ref: FragmentReference;
export type MediaArticlesCard_article = {|
  +nodeType: "Explainer",
  +id: string,
  +dbid: ?number,
  +title: ?string,
  +$refType: MediaArticlesCard_article$ref,
|} | {|
  +nodeType: "FactCheck",
  +id: string,
  +dbid: ?number,
  +title: ?string,
  +rating: ?string,
  +claim_description: ?{|
    +id: string
  |},
  +$refType: MediaArticlesCard_article$ref,
|} | {|
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  +nodeType: "%other",
  +$refType: MediaArticlesCard_article$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MediaArticlesCard_article",
  "type": "Node",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": "nodeType",
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "type": "Explainer",
      "selections": [
        v0,
        v1,
        v2
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "FactCheck",
      "selections": [
        v0,
        v1,
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "rating",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "claim_description",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimDescription",
          "plural": false,
          "selections": [
            v0
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a4af3a347a2374b172f7a6635b3ada85';
module.exports = node;
