/**
 * @flow
 * @relayHash aa76caa5b99835034c52160ec2bf8408
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ArticleCoreListCounterQueryVariables = {|
  slug: string,
  type: string,
  imported?: ?boolean,
  report_status?: ?$ReadOnlyArray<?string>,
|};
export type ArticleCoreListCounterQueryResponse = {|
  +team: ?{|
    +articles_count: ?number,
    +factChecksCount: ?number,
    +explainersCount: ?number,
  |}
|};
export type ArticleCoreListCounterQuery = {|
  variables: ArticleCoreListCounterQueryVariables,
  response: ArticleCoreListCounterQueryResponse,
|};
*/


/*
query ArticleCoreListCounterQuery(
  $slug: String!
  $type: String!
  $imported: Boolean
  $report_status: [String]
) {
  team(slug: $slug) {
    articles_count(article_type: $type, imported: $imported, report_status: $report_status)
    factChecksCount: articles_count(article_type: "fact-check")
    explainersCount: articles_count(article_type: "explainer")
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "imported",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "report_status",
    "type": "[String]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "articles_count",
  "args": [
    {
      "kind": "Variable",
      "name": "article_type",
      "variableName": "type",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "imported",
      "variableName": "imported",
      "type": "Boolean"
    },
    {
      "kind": "Variable",
      "name": "report_status",
      "variableName": "report_status",
      "type": "[String]"
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": "factChecksCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "article_type",
      "value": "fact-check",
      "type": "String"
    }
  ],
  "storageKey": "articles_count(article_type:\"fact-check\")"
},
v4 = {
  "kind": "ScalarField",
  "alias": "explainersCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "article_type",
      "value": "explainer",
      "type": "String"
    }
  ],
  "storageKey": "articles_count(article_type:\"explainer\")"
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ArticleCoreListCounterQuery",
  "id": null,
  "text": "query ArticleCoreListCounterQuery(\n  $slug: String!\n  $type: String!\n  $imported: Boolean\n  $report_status: [String]\n) {\n  team(slug: $slug) {\n    articles_count(article_type: $type, imported: $imported, report_status: $report_status)\n    factChecksCount: articles_count(article_type: \"fact-check\")\n    explainersCount: articles_count(article_type: \"explainer\")\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ArticleCoreListCounterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ArticleCoreListCounterQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5c2ef240c2c4b06a708a2815d651fb3';
module.exports = node;
