/**
 * @flow
 * @relayHash 12a0c77e59d6580ef765043cb579c384
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TeamTasksQueryVariables = {|
  slug: string
|};
export type TeamTasksQueryResponse = {|
  +about: ?{|
    +file_max_size: ?string,
    +file_extensions: ?$ReadOnlyArray<?string>,
  |},
  +team: ?{|
    +id: string,
    +dbid: ?number,
    +slug: string,
    +team_tasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dbid: ?number,
          +label: ?string,
          +description: ?string,
          +options: ?any,
          +type: ?string,
          +associated_type: ?string,
          +json_schema: ?string,
          +show_in_browser_extension: ?boolean,
          +required: ?boolean,
          +conditional_info: ?string,
          +tasks_count: ?number,
        |}
      |}>
    |},
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +title: string,
          +dbid: ?number,
          +id: string,
          +medias_count: ?number,
        |}
      |}>
    |},
  |},
|};
export type TeamTasksQuery = {|
  variables: TeamTasksQueryVariables,
  response: TeamTasksQueryResponse,
|};
*/


/*
query TeamTasksQuery(
  $slug: String!
) {
  about {
    file_max_size
    file_extensions
    id
  }
  team(slug: $slug) {
    id
    dbid
    slug
    team_tasks(fieldset: "metadata", first: 10000) {
      edges {
        node {
          id
          dbid
          label
          description
          options
          type
          associated_type
          json_schema
          show_in_browser_extension
          required
          conditional_info
          tasks_count
        }
      }
    }
    projects(first: 10000) {
      edges {
        node {
          title
          dbid
          id
          medias_count
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "file_max_size",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "file_extensions",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000,
  "type": "Int"
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "slug",
      "type": "String"
    }
  ],
  "concreteType": "Team",
  "plural": false,
  "selections": [
    v3,
    v4,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team_tasks",
      "storageKey": "team_tasks(fieldset:\"metadata\",first:10000)",
      "args": [
        {
          "kind": "Literal",
          "name": "fieldset",
          "value": "metadata",
          "type": "String"
        },
        v5
      ],
      "concreteType": "TeamTaskConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TeamTaskEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TeamTask",
              "plural": false,
              "selections": [
                v3,
                v4,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "label",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "options",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "associated_type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "json_schema",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "show_in_browser_extension",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "required",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "conditional_info",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "tasks_count",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "projects",
      "storageKey": "projects(first:10000)",
      "args": [
        v5
      ],
      "concreteType": "ProjectConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProjectEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Project",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                v4,
                v3,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "medias_count",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamTasksQuery",
  "id": null,
  "text": "query TeamTasksQuery(\n  $slug: String!\n) {\n  about {\n    file_max_size\n    file_extensions\n    id\n  }\n  team(slug: $slug) {\n    id\n    dbid\n    slug\n    team_tasks(fieldset: \"metadata\", first: 10000) {\n      edges {\n        node {\n          id\n          dbid\n          label\n          description\n          options\n          type\n          associated_type\n          json_schema\n          show_in_browser_extension\n          required\n          conditional_info\n          tasks_count\n        }\n      }\n    }\n    projects(first: 10000) {\n      edges {\n        node {\n          title\n          dbid\n          id\n          medias_count\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamTasksQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "about",
        "storageKey": null,
        "args": null,
        "concreteType": "About",
        "plural": false,
        "selections": [
          v1,
          v2
        ]
      },
      v6
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamTasksQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "about",
        "storageKey": null,
        "args": null,
        "concreteType": "About",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3
        ]
      },
      v6
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ceaf55782a4351e208b3507ff1503d8c';
module.exports = node;
