/**
 * @flow
 * @relayHash bf9f5a9cddf3bbc13d138618fb65cabb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MediaArticlesCard_article$ref = any;
type MediaArticlesCard_team$ref = any;
export type MediaArticlesTeamArticlesQueryVariables = {|
  slug: string,
  textSearch: string,
  numberOfArticles: number,
  targetId?: ?number,
|};
export type MediaArticlesTeamArticlesQueryResponse = {|
  +team: ?{|
    +factChecks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id?: string,
          +created_at?: ?string,
          +$fragmentRefs: MediaArticlesCard_article$ref,
        |}
      |}>
    |},
    +explainers: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id?: string,
          +created_at?: ?string,
          +$fragmentRefs: MediaArticlesCard_article$ref,
        |}
      |}>
    |},
    +$fragmentRefs: MediaArticlesCard_team$ref,
  |}
|};
export type MediaArticlesTeamArticlesQuery = {|
  variables: MediaArticlesTeamArticlesQueryVariables,
  response: MediaArticlesTeamArticlesQueryResponse,
|};
*/


/*
query MediaArticlesTeamArticlesQuery(
  $slug: String!
  $textSearch: String!
  $numberOfArticles: Int!
  $targetId: Int
) {
  team(slug: $slug) {
    ...MediaArticlesCard_team
    factChecks: articles(first: $numberOfArticles, sort: "id", sort_type: "desc", article_type: "fact-check", text: $textSearch, target_id: $targetId, standalone: true) {
      edges {
        node {
          __typename
          ... on FactCheck {
            id
            created_at
            ...MediaArticlesCard_article
          }
          ... on Node {
            id
          }
        }
      }
    }
    explainers: articles(first: $numberOfArticles, sort: "id", sort_type: "desc", article_type: "explainer", text: $textSearch, target_id: $targetId) {
      edges {
        node {
          __typename
          ... on Explainer {
            id
            created_at
            ...MediaArticlesCard_article
          }
          ... on Node {
            id
          }
        }
      }
    }
    id
  }
}

fragment MediaArticlesCard_team on Team {
  verification_statuses
}

fragment MediaArticlesCard_article on Node {
  nodeType: __typename
  ... on Explainer {
    id
    dbid
    title
  }
  ... on FactCheck {
    id
    dbid
    title
    rating
    claim_description {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "textSearch",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "numberOfArticles",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "targetId",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug",
    "type": "String"
  }
],
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "numberOfArticles",
  "type": "Int"
},
v3 = {
  "kind": "Literal",
  "name": "sort",
  "value": "id",
  "type": "String"
},
v4 = {
  "kind": "Literal",
  "name": "sort_type",
  "value": "desc",
  "type": "String"
},
v5 = {
  "kind": "Variable",
  "name": "target_id",
  "variableName": "targetId",
  "type": "Int"
},
v6 = {
  "kind": "Variable",
  "name": "text",
  "variableName": "textSearch",
  "type": "String"
},
v7 = [
  {
    "kind": "Literal",
    "name": "article_type",
    "value": "fact-check",
    "type": "String!"
  },
  v2,
  v3,
  v4,
  {
    "kind": "Literal",
    "name": "standalone",
    "value": true,
    "type": "Boolean"
  },
  v5,
  v6
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created_at",
  "args": null,
  "storageKey": null
},
v10 = [
  v8,
  v9,
  {
    "kind": "FragmentSpread",
    "name": "MediaArticlesCard_article",
    "args": null
  }
],
v11 = [
  {
    "kind": "Literal",
    "name": "article_type",
    "value": "explainer",
    "type": "String!"
  },
  v2,
  v3,
  v4,
  v5,
  v6
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": "nodeType",
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "claim_description",
  "storageKey": null,
  "args": null,
  "concreteType": "ClaimDescription",
  "plural": false,
  "selections": [
    v8
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MediaArticlesTeamArticlesQuery",
  "id": null,
  "text": "query MediaArticlesTeamArticlesQuery(\n  $slug: String!\n  $textSearch: String!\n  $numberOfArticles: Int!\n  $targetId: Int\n) {\n  team(slug: $slug) {\n    ...MediaArticlesCard_team\n    factChecks: articles(first: $numberOfArticles, sort: \"id\", sort_type: \"desc\", article_type: \"fact-check\", text: $textSearch, target_id: $targetId, standalone: true) {\n      edges {\n        node {\n          __typename\n          ... on FactCheck {\n            id\n            created_at\n            ...MediaArticlesCard_article\n          }\n          ... on Node {\n            id\n          }\n        }\n      }\n    }\n    explainers: articles(first: $numberOfArticles, sort: \"id\", sort_type: \"desc\", article_type: \"explainer\", text: $textSearch, target_id: $targetId) {\n      edges {\n        node {\n          __typename\n          ... on Explainer {\n            id\n            created_at\n            ...MediaArticlesCard_article\n          }\n          ... on Node {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment MediaArticlesCard_team on Team {\n  verification_statuses\n}\n\nfragment MediaArticlesCard_article on Node {\n  nodeType: __typename\n  ... on Explainer {\n    id\n    dbid\n    title\n  }\n  ... on FactCheck {\n    id\n    dbid\n    title\n    rating\n    claim_description {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MediaArticlesTeamArticlesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "MediaArticlesCard_team",
            "args": null
          },
          {
            "kind": "LinkedField",
            "alias": "factChecks",
            "name": "articles",
            "storageKey": null,
            "args": v7,
            "concreteType": "ArticleUnionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ArticleUnionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "type": "FactCheck",
                        "selections": v10
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "explainers",
            "name": "articles",
            "storageKey": null,
            "args": v11,
            "concreteType": "ArticleUnionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ArticleUnionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "type": "Explainer",
                        "selections": v10
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaArticlesTeamArticlesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "factChecks",
            "name": "articles",
            "storageKey": null,
            "args": v7,
            "concreteType": "ArticleUnionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ArticleUnionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      v12,
                      v8,
                      {
                        "kind": "InlineFragment",
                        "type": "FactCheck",
                        "selections": [
                          v9,
                          v13,
                          v14,
                          v15,
                          v16,
                          v17
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "explainers",
            "name": "articles",
            "storageKey": null,
            "args": v11,
            "concreteType": "ArticleUnionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ArticleUnionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      v12,
                      v8,
                      {
                        "kind": "InlineFragment",
                        "type": "Explainer",
                        "selections": [
                          v9,
                          v13,
                          v14,
                          v15,
                          {
                            "kind": "InlineFragment",
                            "type": "FactCheck",
                            "selections": [
                              v16,
                              v17
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v8
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '09fa9df39de928000d6e1c39122eaacf';
module.exports = node;
